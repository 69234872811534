<template>
  <div></div>
</template>

<script>
export default {
  name: "LogOut",

  created() {
    this.api_signOut()
  }
}
</script>

<style scoped>

</style>